<template>
  <div>
    <!-- 主体 -->
    <LiefengContent>
      <template v-slot:title>平台系统管理</template>
      <!-- 头部搜索层 -->
      <template v-slot:toolsbarRight>
        <Cascader transfer :data="controlForm.systemTree" placeholder="请输入父级系统" clearable change-on-select v-model="controlForm.parentId" style="width: 200px;margin-right:10px"></Cascader>
        <Input v-model.trim="searchForm.keyword" placeholder="请输入关键字" style="width: 160px;margin-right:10px"></Input>
        <Button type="primary" icon="ios-search" @click="searchBtn" style="margin-right: 10px">查询</Button>
        <Button type="success" @click="resetBtn" icon="ios-refresh" style="margin-right: 10px">重置</Button>
        <Button
            icon="ios-add"
            type="primary"
            @click="addForm"
        >新增
        </Button>
      </template>
      <!-- 分页层 -->
      <template v-slot:contentArea>
        <!-- 分页table -->
        <LiefengTable
          :talbeColumns="pageForm.talbeColumns"
          :tableData="pageForm.tableData"
          :loading="pageForm.loading"
          :fixTable="true"
          :curPage="pageForm.page"
          :total="pageForm.total"
          :pagesizeOpts="[20, 30, 50, 100]"
          :page-size="pageForm.pageSize"
          @hadlePageSize="changeTableSize"
          @tableSelect="tableSelect"
        ></LiefengTable>
        <FormAdd ref="FormAdd" @getTreeLsit='selectTree' @resetBtn="resetBtn" @getFatherSystemList="getFatherSystemList"></FormAdd>
        <FormEdit ref="FormEdit" @getTreeLsit='selectTree' @resetBtn="resetBtn" @getFatherSystemList="getFatherSystemList"></FormEdit>
      </template>
      <!-- 新增modal -->
    </LiefengContent>
  </div>
</template>

<script>
import LiefengContent from "@/components/LiefengContent3";
import LiefengTable from "@/components/LiefengTable";
import LiefengModal from "@/components/LiefengModal";
import FormAdd from './childrens/add'
import FormEdit from './childrens/edit'

export default {
  components: {LiefengContent, LiefengTable, LiefengModal, FormAdd, FormEdit},
  data() {
    return {
      systemTypeList: [{'dictName': '真实的', 'dictValue': '1'}, {'dictName': '虚拟的', 'dictValue': '2'}, {'dictName': '首页导航', 'dictValue': '3'}],
      //分页参数
      pageForm: {
        talbeColumns: [
          {
            type: "selection",
            width: 60,
            align: "center"
          },
          {
            title: "系统名称",
            key: "appName",
            minWidth: 200,
            align: "center"
          },
          {
            title: "系统编码",
            key: "appCode",
            minWidth: 200,
            align: "center"
          },
          {
            title: "系统分类",
            minWidth: 200,
            align: "center",
            render: (h, params) => {
              for (let i = 0; i < this.systemTypeList.length; i++) {
                  if (this.systemTypeList[i].dictValue == params.row.systemType) {
                    return h("div", {}, this.systemTypeList[i].dictName)
                  }
              }
            }
          },
          // {
          //   title: "客户端",
          //   key: "clientType",
          //   minWidth: 200,
          //   align: "center"
          // },
          // {
          //   title: "系统说明",
          //   key: "systemDesc",
          //   minWidth: 200,
          //   align: "center"
          // },
          // {
          //   title: "备注",
          //   key: "remark",
          //   minWidth: 200,
          //   align: "center"
          // },
          {
            title: "系统域名",
            minWidth: 200,
            align: "center",
            render:( h,params) => {
              if(params.row.paraJson){
                return h(
                  'div',{},params.row.paraJson.systemIndex?params.row.paraJson.systemIndex:''
                )
              }
              
            }
          },

          {
            title: "创建时间",
            key: "gmtCreate",
            minWidth: 200,
            align: "center",
            render:(h,params) => {
              return h(
                'div',{},params.row.gmtCreate?this.$core.formatDate(new Date(params.row.gmtCreate),'yyyy-MM-dd'):''
              )
            }
          },
          {
            title: "系统logo",
            minWidth: 200,
            align: "center",
            render: (h, params) => {
              if(params.row.paraJson){
              if ( !params.row.paraJson.systemLogo) {
                return h("div", {}, "无")
              }
              return h("div",[h("Avatar",
                  {
                    props:{
                      src: params.row.paraJson.systemLogo
                    }
                  })])
              }
            }
          },
          {
            title: "形象图片",
            minWidth: 200,
            align: "center",
            render: (h, params) => {
              if(params.row.paraJson){
                if (!params.row.paraJson.systemImage) {
                  return h("div", {}, "无")
                }
                return h("div",[h("Avatar",
                  {
                    props:{
                      src: params.row.paraJson.systemImage
                    }
                  })])
              }
            }
          },
          {
            title: "操作",
            align: "center",
            fixed: "right",
            width: 180,
            render: (h, params) => {
              return h(
                  "div",
                  {
                    style: {
                      textAlign: "center"
                    }
                  },
                  [
                    h(
                        "Dropdown",
                        {
                          props: {
                            transfer: true
                          }
                        },
                        [
                          h(
                              "Button",
                              {
                                props: {
                                  type: "success",
                                  size: "small",
                                  icon: "ios-arrow-down"
                                }
                              },
                              "操作"
                          ),
                          h(
                              "DropdownMenu",
                              {
                                slot: "list"
                              },
                              [
                                h(
                                    "DropdownItem",
                                    {
                                      nativeOn: {
                                        click: () => {
                                          // this.$router.push(`/authCloudMenu?systemId=${params.row.systemId}`)
                                          this.$core.openLayerFrame({
                                            type:2,
                                            title:'菜单管理',
                                            content:`/page#/authCloudMenu?systemId=${params.row.systemId}`,
                                            area:['100%','100%']
                                          },()=>{
                                            this.testlog()
                                          })
                                        }
                                      },
                                      style: {
                                        textAlign: "center"
                                      }
                                    },
                                    "菜单管理"
                                ),
                                h(
                                    "DropdownItem",
                                    {
                                      nativeOn: {
                                        click: () => {
                                          this.$core.openLayerFrame({
                                            type:2,
                                            title:'栏目管理',
                                            content:`/page#/columnconfigindex?systemId=${params.row.systemId}`,
                                            area:['100%','100%']
                                          },()=>{
                                            this.testlog()
                                          })
                                        }
                                      },
                                      style: {
                                        textAlign: "center"
                                      }
                                    },
                                    "栏目管理"
                                ),
                                // h(
                                //     "DropdownItem",
                                //     {
                                //       nativeOn: {
                                //         click: () => {
                                //           this.$router.push(`/authCloudSystemFunction?systemId=${params.row.systemId}`)
                                //         }
                                //       },
                                //       style: {
                                //         textAlign: "center"
                                //       }
                                //     },
                                //     "组件管理"
                                // ),
                                h(
                                    "DropdownItem",
                                    {
                                      nativeOn: {
                                        click: () => {
                                          this.updateTab(params.row.systemId)
                                        }
                                      },
                                      style: {
                                        textAlign: "center"
                                      }
                                    },
                                    "修改"
                                ),

                                h(
                                    "DropdownItem",
                                    {
                                      nativeOn: {
                                        click: () => {
                                          this.deleteRow(params.row.systemId)
                                        }
                                      },
                                      style: {
                                        textAlign: "center"
                                      }
                                    },
                                    "删除"
                                ),
                              ]
                          )
                        ]
                    )
                  ]
              );
            }
          },

        ],
        tableData: [],
        loading: false,
        page: 1,
        pageSize: 20,
        total: 0,
        currentPage: 0
      },
      //控制的一些属性
      controlForm:{
        parentId:[],
        systemTree:[],
      },
      //查询参数
      searchForm: {
        parentId: 0,
        keyword: ''
      },
    }
  },
  //create函数,刚加载页面时调用
  created() {
    this.getFatherSystemList();
    this.selectPage()
    this.selectTree();
  },
  methods: {
    /*----- 分页事件部分 -------*/
    testlog(){
      console.log('触发回调函数');
    },
    //分页事件改变
    changeTableSize(val) {
      this.pageForm.page = val.page
      this.pageForm.pageSize = val.pageSize
      this.selectPage()
    },
    // 选框
    tableSelect(){

    },
    //查询按钮
    searchBtn() {
      this.pageForm.page = 1
      this.selectPage()
    },
    //重置按钮
    resetBtn() {
      this.searchForm = {
        keyword: '',
        parentId: 0,
      }
      this.controlForm.parentId = [];
      this.pageForm.page = 1;
      this.selectPage();
    },


    /*----- modal部分 -------*/
    //新增按钮
    addForm() {
      this.$refs.FormAdd.controlForm.formFlag = true;
    },
    getFatherSystemList() {
      this.$get('/auth/api/auth/pc/system/selectAllFatherSystem', {
      }).then(res => {
        if (res.code == 200) {
          //parentid为0时，为无
          if (res.dataList.length != 0) {
            res.dataList.unshift({systemId : '0', appName : '无'});
          } else {
            res.dataList = [{systemId : '0', appName : '无'}];
          }
          this.$refs.FormAdd.controlForm.fatherSystemList = res.dataList
          this.$refs.FormEdit.controlForm.fatherSystemList = res.dataList
        } else {
          this.$Message.error({
            content: '获取列表失败',
            background: true
          })
          return
        }
      })
    },
    // 方法部分

    // 父级查询条件
    selectTree() {
      this.$get('/auth/api/auth/pc/system/selectSystemTree', {

      }).then(res => {
        if (res.code == 200) {
          this.controlForm.systemTree = res.dataList
        } else {
          this.$Message.error({
            content: '获取列表失败',
            background: true
          })
          return
        }
      })
    },

    // 分页列表
    selectPage() {
      if (this.controlForm.parentId.length>=1) {
        this.searchForm.parentId = this.controlForm.parentId[this.controlForm.parentId.length - 1];
      }else {
        this.searchForm.parentId = 0
      }
      this.pageForm.loading = true;
      this.$get('/auth/api/auth/pc/system/selectByPage', {
        page: this.pageForm.page,
        pageSize: this.pageForm.pageSize,
        ...this.searchForm,
      }).then(res => {
        this.pageForm.loading = false
        if (res.code == 200) {
          this.pageForm.tableData = res.dataList
          this.pageForm.tableData = this.pageForm.tableData.map( e=> {
            return {
              ...e,
              paraJson:JSON.parse(e.paraJson)
            }
          })
          this.pageForm.total = res.maxCount
          this.pageForm.currentPage = res.currentPage
        } else {
          this.$Message.error({
            content: '获取列表失败',
            background: true
          })
          return
        }
      })
    },
    // 获取详情接口
    updateTab(systemId){
      this.$refs.FormEdit.getSystemDetail(systemId)
      this.$refs.FormEdit.controlForm.formFlag = true
    },

    // 删除
    deleteRow(systemId) {
      this.$Modal.confirm({
        title: "删除确定",
        content: "您正在对系统进行删除操作、请确保系统下无数据、并且当前操作不可逆转，是否继续？",
        onOk: () => {
          this.$post('/auth/api/auth/pc/system/deleteBySystemId',{
            systemId
          }).then(res=>{
            if(res.code == 200){
              this.$Message.success({
                content:'删除成功',
                background:true
              })
              this.resetBtn();
              this.selectTree()
                this.$refs.FormAdd.selectTree()
                this.$refs.FormEdit.selectTree()
            }else{
              this.$Message.error({
                content:'删除失败',
                background:true
              })
              return
            }
          })
        },
      });
    },

  }
}
</script>
<style lang="less" scoped>
/deep/ #toolsbarRight {
  width: 1000%;

  .search {
    width: 100%;
    text-align: right;

    .ivu-form-item {
      margin-bottom: 0;
    }
  }
}

// /deep/.ivu-modal-close {
//   display: none;
// }
</style>